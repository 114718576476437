const dateOfBirth = document.querySelectorAll('.dateOfBirth');
const documentExpiryDate = document.querySelectorAll('.documentExpiryDate');
const editNewPageLink = document.querySelectorAll('.back_link');

editNewPageLink.forEach((el) =>
  el.addEventListener('click', () => {
    window.history.go(-1);
  }),
);

dateOfBirth.forEach((el) =>
  el.addEventListener('keyup', (e) => {
    const day = document.getElementById('dateOfBirthDay').value;
    const month = document.getElementById('dateOfBirthMonth').value;
    const year = document.getElementById('dateOfBirthYear').value;
    document.getElementById('dateOfBirthValidFormat').value =
      `${day}/${month}/${year}`;
  }),
);

documentExpiryDate.forEach((el) =>
  el.addEventListener('keyup', () => {
    const day = document.getElementById('documentExpiryDateDay').value;
    const month = document.getElementById('documentExpiryDateMonth').value;
    const year = document.getElementById('documentExpiryDateYear').value;
    document.getElementById('documentExpiryDateValidFormat').value =
      `${day}/${month}/${year}`;
  }),
);

if (document.getElementById('dateOfBirthDay')) {
  document.getElementById('dateOfBirthDay').addEventListener('keydown', (e) => {
    inputOnlyNumber(e);
  });
}

if (document.getElementById('dateOfBirthMonth')) {
  document
    .getElementById('dateOfBirthMonth')
    .addEventListener('keydown', (e) => {
      inputOnlyNumber(e);
    });
}

if (document.getElementById('dateOfBirthYear')) {
  document
    .getElementById('dateOfBirthYear')
    .addEventListener('keydown', (e) => {
      inputOnlyNumber(e);
    });
}

if (document.getElementById('documentExpiryDateDay')) {
  document
    .getElementById('documentExpiryDateDay')
    .addEventListener('keydown', (e) => {
      inputOnlyNumber(e);
    });
}

if (document.getElementById('documentExpiryDateMonth')) {
  document
    .getElementById('documentExpiryDateMonth')
    .addEventListener('keydown', (e) => {
      inputOnlyNumber(e);
    });
}

if (document.getElementById('documentExpiryDateYear')) {
  document
    .getElementById('documentExpiryDateYear')
    .addEventListener('keydown', (e) => {
      inputOnlyNumber(e);
    });
}

function inputOnlyNumber(e) {
  if (
    isNaN(e.key) &&
    e.key !== 'Backspace' &&
    e.key !== 'Tab' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight'
  ) {
    e.preventDefault();
  }
}
